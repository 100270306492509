import { SectionHeader } from "~/components/dash/section-header";
import { For, Show, Suspense } from "solid-js";
import DownloadIcon from "lucide-solid/icons/download";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/solid-ui/table";
import { createAsync, query } from "@solidjs/router";
import { db } from "~/lib/db/setup";
import { waitlist } from "~/lib/db/schemas";

const fetchUsers = query(async () => {
  "use server";

  const users = await db.select().from(waitlist).all();

  return users;
}, "users");

export const route = {
  preload: async () => {
    const users = await fetchUsers();
    return { users };
  },
};

export default function Template() {
  const users = createAsync(() => fetchUsers());

  return (
    <article class="p-8">
      <div class="flex justify-between items-center mb-8">
        <SectionHeader
          title="Subscribers"
          subtitle="Manage your subscribers."
        />
        <button class="flex gap-2 text-neutral-200 py-2 px-4 rounded-sm text-sm bg-sky-700/20 hover:bg-sky-700 transition-colors">
          <DownloadIcon class="w-5 relative -top-1" /> Download Users
        </button>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>id</TableHead>
            <TableHead>Preferred Name</TableHead>
            <TableHead>e-mail</TableHead>
            <TableHead class="text-right">Created at</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <Suspense fallback="Loading...">
            <For each={users()}>
              {(user) => (
                <TableRow>
                  <TableCell class="text-right">{user.id}</TableCell>
                  <TableCell class="font-medium">
                    {user.preferred_name ?? "N/A"}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Show when={user.created_at} fallback="N/A">
                      {(time) => (
                        <span>
                          {new Date(time()).toLocaleDateString("en-CA", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })}
                        </span>
                      )}
                    </Show>
                  </TableCell>
                </TableRow>
              )}
            </For>
          </Suspense>
        </TableBody>
      </Table>
    </article>
  );
}
